import React from "react"
import { graphql } from "gatsby"


import Layout from "../components/layout"
import SEO from "../components/seo"
import ThreadFeed from '../components/threadfeed'
import { BookTalkT } from "../services/seotemplate"

const BookTalk = ({ data }) => {
  const title = BookTalkT.title
  const description = BookTalkT.description

  const schema = 
  {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": title,
    "description": description
  }


  const threads = data.allBookThreads.nodes

  return (
  <Layout>
    <SEO title={title} description={description} schemaMarkup={schema}/>
    <h1 className="heading">Book Talk</h1>
    <p style={{fontSize:"1.25rem"}}>Find good books to read from book recommendations on twitter threads</p>
    {threads.map(thread =>{
      return(<ThreadFeed parentTweet={thread.question[0]} woa={thread.woa}/>)
    })}
  </Layout>)
}

export const query = graphql`
  {
    allBookThreads(filter: {woa: {elemMatch: {books: {elemMatch: {thumbnail: {ne: null}}}}}}, limit: 50, sort: {fields: question___created_at, order: DESC}) {
        nodes {
            woa {
            books {
                thumbnail
                geniusLink
                affiliateLink
                amazonLink
                onlineLink
                isbn
                title
            }
            score
            }
            question {
            text
            tweet_id_str
            user_screen_name
            user_name
            created_at
            }
        }
    }
  }
`

export default BookTalk