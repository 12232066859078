import React from 'react'
import TweetQuestion  from '../tweetquestion'
import { Image, Row, Col } from "react-bootstrap"
import { Link } from 'gatsby'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import './style.css'

function ThreadFeed({parentTweet,woa}){
    const threadLink = '/thread/' + parentTweet.tweet_id_str
    const num_of_books = 4
    const top_woa = woa.slice(0,num_of_books)
    return(
        <React.Fragment>
            <Row>
                <TweetQuestion tweet={parentTweet}/>
            </Row>
            <Row className="book-row">
                {top_woa.map( woa_item => {
                    const book = woa_item.books[0]
                    if(typeof book != "undefined" && 
                        book != null && 
                        book.hasOwnProperty('isbn'))
                    {
                        const id = parentTweet.tweet_id_str + '_' + book.isbn
                        const bookLink = book.geniusLink ? book.geniusLink : book.affiliateLink ? book.affilaiteLink : book.amazonLink
                        return(
                            <Col sm={3} md={3} lg={3} >
                                 <a href={bookLink} target="_blank" rel="noopener noreferrer">
                                    <LazyLoadComponent>
                                        <Image src={book.thumbnail} className="book-image" alt={book.title} rounded fluid/>
                                    </LazyLoadComponent>
                                </a> 
                            </Col>
                        )
                    }        
                })}
            </Row> 
            <Row style={{marginBottom:"2.5rem", marginLeft:"0.5rem", marginTop:"-0.5rem"}}>
                <div style={{ fontSize:"1.25rem"}} >
                    <Link to={threadLink} target="_blank">
                        View All Books
                    </Link>
                </div>
            </Row>
        </React.Fragment>
    )
}

export default ThreadFeed